import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Gallery from "../components/_index-gallery/indexGallery"
import Intro from "../components/_index-intro/intro"
import IndexLinks from "../components/_index-links/indexLinks"
import "../styles/global.scss"

const IndexPage = () => (
    <Layout sectionClass="index">
        <SEO 
            title="Home" 
            keywords={[`gatsby`, `application`, `react`]} 
        />
        <Intro/>
        <Gallery/>
        <IndexLinks/>
    </Layout>
)

export default IndexPage
