import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Parallax } from "react-scroll-parallax"
import style from "./indexGallery.module.scss"

class Gallery extends React.Component {
    render() {
        return (
            <StaticQuery query={graphql`
                query GalleryQuery 
                {
                    allFile(
                        sort: { order: ASC, fields: name }
                        filter: { sourceInstanceName: { eq: "photosHome" } }
                    ) {
                        edges {
                            node {
                                relativePath
                                name
                                childImageSharp {
                                    fluid(maxWidth: 690, srcSetBreakpoints: [ 430, 960 ], fit: COVER, cropFocus: ATTENTION) {
                                        aspectRatio
                                        base64
                                        sizes
                                        src
                                        srcSet
                                        srcSetWebp
                                        srcWebp
                                    }
                                }
                            }
                        }
                    }
                }
            `} 
            render={(data) => (                
                <section className={style.gallery}>
                    <ul className={style.gallery__grid}>
                        {data.allFile.edges.map(({ node: photosHome }) => (
                            <li key={photosHome.name} className={style.gallery__item}>
                                <Parallax y={[-39, 13]}>
                                    <figure className={style.gallery__imageBlock}>
                                        <Img
                                            fluid={{...photosHome.childImageSharp.fluid, aspectRatio: 412/416 }}
                                            placeholderClassName="img-placeholder"
                                        />
                                    </figure>
                                </Parallax>
                            </li>
                        ))}
                    </ul>
                    <div className={`${style.gallery__grid} ${style.gallery__gridFixed}`}>
                        <Parallax y={[0, 52]} className={style.gallery__itemAlter}>
                            {/*<h3 className="u-txt--center">3 May 2019</h3>*/}
                        </Parallax>
                        <Parallax y={[13, 0]} className={style.gallery__itemAlter} />
                        <Parallax y={[0, -26]} className={style.gallery__itemAlter} />
                    </div>
                </section>
            )}
            />
        )
    }
}

export default Gallery
