import React from "react"
import style from "./intro.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export default () => {
    const Sign = () => (
        <svg id="sign"
             xmlns="http://www.w3.org/2000/svg"
             x="0px" y="0px"
             width="234px" height="234px"
             viewBox="0 0 306.65274 168.27934"
             enableBackground="new 0 0 306.65274 168.27934"
             xmlSpace="preserve"
        >
            <g>
                <path fill="#5C5C5F" d="M167.7197,116.01358c2.40114-1.99763,4.61832-5.58936,6.34052-8.06691
                c2.67021-3.79183,5.8558-7.66819,8.74565-11.47725c1.96335-2.59559,3.91127-5.27482,5.87022-7.77829
                c1.18758-1.52827,2.36737-2.94101,3.44975-4.55276c1.01952-1.47824,1.79205-3.06291,2.49066-4.65862
                c0.81718-1.62312,1.45883-3.15913,2.06763-4.793c1.61992-4.28497,2.72523-8.77864,2.94669-13.43736
                c0.00693-0.31036,0.06004-0.48728-0.03098-0.73644l-0.20717-0.806c-0.14734-0.54781-0.38235-1.06725-0.58928-1.59859
                c-0.511-1.01437-1.0903-2.02162-1.87549-2.87683c-1.49673-1.77872-3.502-3.09699-5.58163-4.17693
                c-2.36749-1.19385-4.86508-2.14561-7.3633-3.16173c-2.50069-1.01739-4.9704-2.14167-7.40128-3.35974
                c-4.85199-2.44584-9.57091-5.28942-13.80067-8.80946c-2.10089-1.76848-4.09392-3.70223-5.74194-5.93178
                c-1.63017-2.221-3.02109-4.71572-3.5549-7.4737c-0.33267-1.36235-0.32661-2.77845-0.25693-4.1676
                c0.18196-1.38052,0.45845-2.75791,0.99948-4.03488c1.01628-2.58665,2.62779-4.84361,4.46855-6.86251
                c1.83472-1.87833,3.92279-3.47216,6.17589-4.8087c1.13768-0.64966,2.32477-1.22509,3.57913-1.64731
                c1.24741-0.42308,2.6013-0.66502,3.95708-0.48965c1.3479,0.19373,2.65228,0.85409,3.48895,1.95621
                c0.83293,1.10264,1.14996,2.4631,1.23605,3.76303c0.16481,2.63625-0.48581,5.15965-0.76509,7.69248
                c-0.9187,7.51261-2.14507,14.93758-4.16104,22.251c-1.1326,4.10111-2.57234,8.11285-4.42375,11.93458
                c-1.84966,3.81975-4.08649,7.45685-6.63736,10.85448c-1.29358,1.66988-2.77774,3.20399-4.33662,4.6497
                c-1.56041,1.44685-3.21809,2.79404-4.96353,4.01909c-3.47794,2.40539-7.31639,4.62132-11.62013,5.11176
                c-0.58626,0.0723-1.18149,0.02807-1.77299,0.04458c-0.29135-0.05806-0.59262-0.08461-0.87738-0.16991
                c-0.26624-0.13219-0.5769-0.18643-0.80774-0.38491c-1.01114-0.6581-1.62782-1.81577-1.78549-2.99587
                c-0.17259-1.18281-0.0141-2.42255,0.52037-3.50982c0.29236-0.52447,0.65129-1.04012,1.16685-1.38347
                c0.48576-0.38729,1.11169-0.54404,1.69902-0.64498c0.59656-0.09461,1.28992-0.05577,1.81804,0.30253
                c0.54558,0.34521,0.81691,0.98909,0.90417,1.60836c0.15952,1.24007-0.12857,2.4368-0.51372,3.56744
                c-0.39737,1.13208-0.93951,2.21027-1.62138,3.20414c-0.69859,0.96408-1.50333,1.95522-2.76176,2.39422l-0.19824,0.06916
                l-0.11198-0.19057c-0.44572-0.75854-0.67699-1.60304-0.89381-2.22255c-0.11099-0.33238-0.22676-0.66209-0.37399-0.97626
                c-0.14899-0.30318-0.32907-0.63786-0.66408-0.76062c-0.35619-0.09064-0.67088,0.18222-0.88159,0.44299
                c-0.22998,0.26175-0.43767,0.54218-0.62959,0.82893c-0.20322,0.29269-0.36894,0.55287-0.57588,0.88351
                c-0.21982,0.33908-0.42274,0.69317-0.63492,1.0389c-1.67424,2.79823-3.1525,5.69546-4.86356,8.53038
                c-0.12088,0.17828-0.22665,0.35446-0.36072,0.53432c-0.16225,0.17192-0.30562,0.38805-0.49364,0.51611
                c-0.35458,0.30289-0.74133,0.53122-1.13239,0.71795c-0.78384,0.37685-1.58892,0.63415-2.39122,0.85149
                c-1.60517,0.43492-3.21587,0.73894-4.77121,1.14541c-0.78519,0.20718-1.50677,0.4412-2.24184,0.76443l-2.24105,0.98446
                c-1.50388,0.65751-3.05293,1.29768-4.70268,1.70163c-1.64167,0.4155-3.3979,0.55346-5.11322,0.29958
                c-1.70977-0.28671-3.27628-0.80705-4.83875-1.31493c-1.54952-0.50105-3.07108-1.01001-4.59496-1.17194
                c-1.51986-0.16776-3.03819-0.0107-4.47116,0.5329c-0.71451,0.26177-1.42094,0.60252-2.08596,0.97424
                c-0.65121,0.36758-1.2566,0.8426-1.83612,1.35284c-1.16084,1.02495-2.18772,2.25093-3.18655,3.51187
                c-2.00312,2.52424-3.82513,5.30251-6.3524,7.65895c-1.11371,1.03414-2.41503,1.95852-3.92048,2.55587
                c-1.50601,0.57727-3.20425,0.77962-4.7878,0.51868c-0.77653-0.09348-1.63009-0.34806-2.37047-0.68376
                c-0.74851-0.3445-1.41571-0.78554-2.04977-1.25002c-1.23769-0.95605-2.29603-2.04328-3.27906-3.15398
                c-1.95726-2.22349-3.65661-4.57243-5.54107-6.63307c-1.8416-2.03436-4.05003-3.89357-6.30937-3.77158
                c-0.54507,0.01225-1.11738,0.20496-1.69031,0.37101l-0.81975,0.38917c-0.26981,0.1412-0.51796,0.34027-0.78064,0.50386
                c-1.01525,0.74021-1.91311,1.72984-2.71459,2.82191c-1.57975,2.22672-2.78637,4.80513-3.9474,7.35921
                c-0.49092,1.12518-0.94736,2.17136-1.39822,3.20477c-0.4636,1.01723-0.92131,2.02154-1.38739,3.04422
                c-0.89869,1.95396-1.82743,3.97328-2.82001,6.13139c-1.78105,3.7643-4.10147,8.29597-5.38932,9.50338
                c-0.97116,2.09465-1.85753,4.23274-2.62449,6.41687c-0.77578,2.1674-1.36363,4.43906-2.08237,6.69263
                c-1.40007,4.49654-2.93066,8.94978-4.26981,13.44658c-1.31147,4.48672-2.56214,9.0601-2.97622,13.61324
                c-0.03528,0.54225-0.05353,1.14803,0.02934,1.52696c0.00923,0.04634,0.01969,0.07767,0.02588,0.09142
                c0.00257,0.02124,0.03038-0.01604-0.09845,0.05612c-0.04456,0.02562-0.0885,0.05659-0.12884,0.08913
                c-0.02732,0.01314-0.05368,0.03041-0.07801,0.05183c-0.02359,0.01987-0.0483,0.05042-0.0643,0.07277
                c-0.00808,0.00574-0.0161,0.01414-0.02277,0.02545c-0.02038,0.04485-0.00077,0.00752-0.00566-0.00453l-0.00089-0.00459
                c-0.00028,0.10439-0.00103-0.2395-0.00112,0.23334c0.00278-0.02086,0.00035-0.04094-0.00667-0.05814
                c-0.013-0.02493-0.04288-0.05281-0.09297-0.13754c-0.047-0.0648-0.10444-0.11978-0.16894-0.16318
                c-0.03106-0.01875-0.08333-0.07912-0.12583-0.11423l-0.06849-0.05986l-0.0012-0.00235
                c0.04673-0.05197,0.1995-0.36655,0.29408-0.5954c0.42313-1.02786,0.78421-2.15033,1.13856-3.25475
                c0.70346-2.22221,1.35703-4.46942,2.00861-6.7117c2.12222-7.38573,4.24267-14.79112,5.7051-22.35825
                c0.56058-2.83586,0.9254-5.64391,1.33862-8.45598c0.40593-2.81581,0.81582-5.65908,1.21664-8.4394
                c0.85329-5.66706,1.86135-11.40412,2.86661-17.12537l2.26377-12.9187l1.10429-6.32073
                c-0.60878,0.02827-0.48674-3.0163,0.02667-5.68177c0.51395-2.66519,1.28164-4.99834,1.99908-7.3063
                c0.3363-1.11668,0.66336-2.20272,0.98348-3.26567c0.32372-1.06126,0.63291-2.19455,0.94271-3.3175
                c0.63712-2.36608,1.25095-4.64571,1.8526-6.88008c1.24551-4.64906,2.52264-9.40775,3.95821-14.17374
                c1.44439-4.76075,3.01582-9.54793,5.05864-14.1391c1.03637-2.28579,2.17176-4.53657,3.59789-6.60748
                c1.42901-2.03359,3.12693-4.04455,5.50701-5.07235c1.20949-0.52617,2.59479-0.68627,3.88293-0.39708
                c1.29247,0.26622,2.46302,0.89024,3.47981,1.65425c2.02685,1.54993,3.62617,3.54844,4.8518,5.71766
                c2.45843,4.3616,3.61837,9.24017,4.33158,14.11385c0.74282,5.52631,1.31315,10.8158,1.72315,16.10728
                c0.39606,5.29467,0.56589,10.61972,0.24532,16.2154c-0.22839,4.15514-0.82803,8.30731-1.78197,12.39019
                c-0.98044,4.06898-2.31995,8.10757-4.44151,11.77701c-2.09929,3.63511-5.14889,6.95084-9.16158,8.54758
                c-3.94988,1.68912-8.44297,1.69614-12.53531,0.66887c-4.97702-1.26028-9.48009-3.78435-13.77459-6.45599
                c-4.17414-2.93321-8.14638-6.04847-12.10737-9.22385c-3.93852-3.19016-7.79522-6.49783-11.35714-10.12071
                c-1.7781-1.80989-3.46903-3.72931-4.85212-5.88326c-0.64168-1.08647-1.34484-2.23457-1.43237-3.57219
                c-0.13481-1.28427,0.311-2.76954,1.49716-3.44445c-0.53667,0.74084-0.6797,1.81523-0.41646,2.68472
                c0.12289,0.44685,0.31698,0.87779,0.56482,1.27921c0.12468,0.20216,0.26283,0.38995,0.41517,0.57941
                c0.18351,0.2137,0.36487,0.42491,0.54434,0.63391c1.45984,1.69513,3.44874,3.48508,5.47196,5.29681
                c4.07246,3.6198,8.49451,7.37658,12.96701,10.99969c2.64966,2.13232,5.35264,4.21383,8.08381,6.26852l1.89554,1.41228
                l2.11942,1.31326c1.42561,0.86758,2.85786,1.67922,4.30624,2.40804c2.88925,1.47513,5.8647,2.59034,8.83103,3.07326
                c1.48197,0.22205,2.95012,0.26115,4.39268,0.14558c1.42678-0.19626,2.84366-0.47712,4.18179-1.05249
                c2.60695-1.01332,4.78434-2.97868,6.55553-5.46089c1.75977-2.48972,3.10638-5.4192,4.15376-8.4896
                c2.083-6.17981,3.14971-12.83848,3.50331-19.47613c0.29869-5.83781-0.10604-11.87196-0.85374-17.8488
                c-0.79662-5.99167-1.64245-11.98663-3.19858-17.27112c-0.42619-1.47952-0.92786-2.91074-1.52716-4.26683
                c-0.57936-1.37237-1.28408-2.64765-2.08923-3.82341c-1.58771-2.3267-3.82065-4.43661-5.8746-4.5755
                c-1.03138-0.09339-2.18417,0.2761-3.30173,1.05476c-1.11786,0.76321-2.15693,1.85467-3.10006,3.04367
                c-1.87823,2.41601-3.41341,5.24262-4.75856,8.16035c-1.91201,4.17886-3.4412,8.59211-4.7741,13.07219
                c-1.3315,4.48428-2.45224,9.05226-3.44728,13.65467c-1.91779,9.21843-3.60658,18.56004-5.36698,27.81985l-8.04981,42.82154
                c-1.25428,7.16989-2.82162,14.28427-4.53357,21.34966c-0.85774,3.53407-1.75186,7.05911-2.7065,10.57141
                c-0.47876,1.75601-0.97084,3.50877-1.50109,5.25597c-0.26605,0.8737-0.54057,1.74553-0.84349,2.61714
                c-0.1531,0.43648-0.31013,0.87065-0.49928,1.314c-0.09619,0.22348-0.19197,0.44121-0.35,0.70078
                c-0.03159,0.05759-0.10281,0.14923-0.16327,0.22333c-0.05919,0.06143-0.12629,0.11932-0.19608,0.16908
                c-0.1573,0.10944-0.45068,0.13565-0.48942,0.11246c-0.19397-0.01555-0.3651-0.09901-0.50827-0.21132
                c-0.10798-0.09927-0.20674-0.22264-0.24823-0.31078c-0.25815-0.5329-0.23006-0.86153-0.2654-1.23143
                c-0.01426-0.35612-0.00331-0.69678,0.0152-1.0354c0.03846-0.67593,0.11485-1.33768,0.20326-1.99953
                c0.18009-1.32217,0.41669-2.63689,0.68989-3.95636c0.56209-2.63445,1.26038-5.2529,2.02856-7.84593
                c1.53586-5.18571,3.33322-10.24263,4.90565-15.05109c0.70629-2.18907,1.38155-4.50539,2.21918-6.75801
                c0.82343-2.26274,1.74472-4.51074,2.72953-6.73251c1.96988-4.44482,4.18596-8.78262,6.32587-12.99684l1.77597-3.55402
                c0.56658-1.17254,1.07962-2.39954,1.61824-3.61761c1.069-2.43835,2.20395-4.8911,3.70367-7.16219
                c0.74554-1.13724,1.6251-2.20232,2.62764-3.15769c1.01303-0.94726,2.24324-1.70408,3.59128-2.13906
                c1.32721-0.46939,2.89708-0.46365,4.22538,0.04365c1.34959,0.48401,2.48636,1.325,3.49468,2.23791
                c2.01888,1.84527,3.61226,4.00796,5.27179,6.0751c1.65428,2.05725,3.313,4.15836,5.40031,5.65321
                c2.02927,1.53256,4.79176,1.85635,7.14139,1.013c2.37036-0.86835,4.24639-2.77923,5.89592-4.78193
                c1.33017-1.61721,2.54266-3.34932,3.85152-5.04267c1.3139-1.68369,2.70519-3.37719,4.52625-4.72523
                c0.92955-0.67017,1.91518-1.19935,2.96243-1.61594c1.04087-0.43266,2.16904-0.73138,3.32374-0.82397
                c2.33195-0.23794,4.59214,0.38357,6.60902,1.01016c1.0224,0.31471,2.00939,0.63436,2.99332,0.89221
                c0.4915,0.14005,0.98254,0.24047,1.46947,0.35255c0.46438,0.08092,0.92822,0.15186,1.3973,0.17637
                c1.87647,0.11407,3.76287-0.3315,5.63407-1.04832c0.93787-0.35596,1.87624-0.77388,2.83395-1.20807
                c0.98678-0.43128,1.86499-0.8901,3.0548-1.29865c2.20377-0.71909,4.30267-1.04404,6.22476-1.60166
                c0.89423-0.25481,1.76553-0.57629,2.31284-0.94083c0.08676-0.09184,0.24901-0.17657,0.27419-0.24615
                c0.03775-0.0174,0.17461-0.22037,0.29131-0.40196c0.24258-0.38049,0.4944-0.80347,0.74171-1.22977l2.99344-5.25981
                c0.51343-0.88391,1.03111-1.76716,1.58481-2.65103l0.45644-0.71292c0.23792-0.38253,0.49884-0.7411,0.76598-1.07872
                c0.51071-0.73458,1.10228-1.26945,1.68398-1.60316c0.28777-0.18267,0.58887-0.29252,0.93651-0.3161
                c0.35057-0.03579,0.7498,0.04237,1.04424,0.19896c0.6098,0.2827,1.04332,0.80503,1.42915,1.51726
                c0.36824,0.68884,0.65096,1.68192,0.87694,2.36778c0.14397,0.44222,0.30449,0.85052,0.43335,1.06813l-1.83339-0.7175
                c0.27795-0.06654,0.90218-0.52817,1.36427-1.11091c0.48918-0.58012,0.92961-1.2712,1.29424-1.99603
                c0.36691-0.72334,0.66969-1.48909,0.85704-2.23977c0.10036-0.37546,0.15956-0.74444,0.18785-1.08604
                c0.01619-0.17339,0.0148-0.33088,0.00891-0.47993c-0.01569-0.12158-0.01685-0.21585-0.03331-0.26928
                c-0.0451-0.25771-0.30376-0.4299-0.56538-0.41419c-0.13541,0.02981-0.22281,0.00279-0.40584,0.05409
                c-0.17258,0.03157-0.33656,0.06824-0.41432,0.11789c-0.10454,0.03607-0.21576,0.06398-0.30423,0.15028
                c-0.09848,0.06087-0.20329,0.12019-0.28569,0.23269c-0.76421,0.73917-1.11247,2.42649-0.71892,3.66823
                c0.15068,0.42124,0.32286,0.83926,0.60095,1.091c0.10849,0.17035,0.28664,0.2254,0.41747,0.3554
                c0.17747,0.05482,0.31758,0.17251,0.52005,0.19695c0.31747,0.10875,0.98041,0.06538,1.51515,0.08198
                c0.56558-0.04034,1.14261-0.11414,1.71671-0.21432c2.31252-0.47682,4.60054-1.49,6.7626-2.71369
                c4.3071-2.48808,8.31464-5.77771,11.53253-9.52603c1.53342-1.90501,2.98059-4.06627,4.31177-6.18999
                c1.27365-2.18134,2.5088-4.38841,3.50754-6.70909c2.09583-4.59656,3.52016-9.47913,4.58327-14.44634
                c1.03542-4.98003,1.67157-10.06457,2.19514-15.17977l0.38065-3.88984c0.16154-1.34005,0.35165-2.57344,0.4375-3.78015
                c0.08788-1.19661,0.07697-2.35952-0.14464-3.34358c-0.21329-1.00582-0.66411-1.68202-1.3277-2.14336
                c-0.72447-0.48546-1.53767-0.6848-2.5443-0.59687c-1.00117,0.08287-2.09454,0.41964-3.16188,0.87607
                c-2.1376,0.95919-4.21069,2.34544-6.0681,3.92595c-3.59578,3.0683-6.54118,7.46827-6.64491,12.01082
                c-0.09476,2.172,0.47998,4.3599,1.50369,6.38877c1.01144,2.03669,2.42517,3.92706,4.03169,5.66312
                c3.23642,3.47396,7.17432,6.37189,11.25781,8.96786c2.80103,1.76531,5.70807,3.39296,8.68129,4.87722
                c2.95491,1.51532,6.04144,2.75597,9.05061,4.26083c2.32166,1.16068,4.61497,2.51212,6.56848,4.30757
                c0.98332,0.8901,1.8221,1.93565,2.54843,3.04695c0.68498,1.14647,1.21884,2.35881,1.55644,3.66589
                c0.15192,0.32846,0.04814,0.71912,0.07323,1.02775c-0.002,0.32718-0.00706,0.65427-0.02547,0.98112
                c-0.02017,0.65428-0.07025,1.30692-0.12181,1.9595c-0.12102,1.30364-0.3036,2.60142-0.54942,3.88776
                c-0.50806,2.56889-1.27121,5.08929-2.29442,7.49953c-0.4912,1.18285-1.03867,2.33153-1.62532,3.45273
                c-0.57582,1.05074-1.05243,2.26675-1.63348,3.35945c-1.1172,2.23995-2.32663,4.42229-3.64909,6.5404
                c-2.63811,4.24252-5.64334,8.28117-9.08067,12.20058c-2.09967,2.3931-4.23767,4.65111-6.29102,7.10205
                c-3.92029,4.65357-7.68599,9.34933-11.92619,13.71214c-2.99123,3.08652-6.19107,5.98303-9.36621,8.86229
                c-4.64044,4.20396-9.25531,8.41945-13.64972,12.83972c-2.153,2.20876-4.38698,4.53693-6.71153,6.66177
                c-2.31763,2.15196-4.69351,4.23488-7.16687,6.22934c-2.03728,1.61574-4.07053,3.20332-6.48133,4.5446
                c-0.61204,0.33101-1.25578,0.64307-2.05775,0.88152c-0.19096,0.06186-0.41454,0.11212-0.66582,0.14517
                c-0.21547,0.04716-0.5389,0.04663-0.83609,0.03101c-0.1412-0.0015-0.3648-0.06291-0.54092-0.11072
                c-0.09355-0.02753-0.18434-0.0554-0.27622-0.09053c-0.10257-0.05185-0.20476-0.10962-0.30415-0.17262
                c-0.37827-0.19087-0.79279-0.71552-0.94777-1.17586c-0.06372-0.21684-0.10834-0.44606-0.13095-0.67015
                c-0.00642-0.18585,0.01098-0.33199,0.02563-0.5002c0.02897-0.36388,0.11243-0.52966,0.17978-0.79099
                c0.15154-0.43744,0.32507-0.80382,0.50597-1.13187c0.36175-0.666,0.7494-1.24051,1.14931-1.79242
                c1.9845-2.69429,4.1788-4.99057,6.39451-7.29834c0.73797-0.76024,1.51128-1.54297,2.06422-2.28854
                c0.55203-0.74631,0.80397-1.44061,0.33937-1.40504c0.75859,0.64612,2.79048-0.43782,5.13631-2.08795
                c2.3419-1.65892,5.02724-3.86549,6.9588-5.65123c5.35968-4.94908,10.71748-10.02943,16.28722-14.33052
                c0.67525-0.52151,1.40492-1.08521,2.09047-1.66129l1.50699-1.39276c0.38454-0.40498,0.70981-0.80495,0.93666-1.1895
                c0.34392-0.58295,0.4203-1.07115,1.01073-1.72208c0.51186-0.56435,1.20087-1.01537,1.81039-1.3994l2.35471-1.48241
                c1.61441-1.58198,3.23578-3.17077,4.87234-4.77444c1.18727-1.18306,2.42665-2.42195,3.7516-3.38112
                c0.8522-0.61716,1.67961-1.073,2.53114-1.6722c2.78799-1.9551,5.29089-5.3407,4.69089-6.35478
                c10.13324-8.4726,20.31824-16.87603,30.70406-25.0693c4.21179-4.8372,10.81943-9.01925,16.69406-13.02622
                c5.78682-3.95951,11.51778-8.35681,16.67653-12.81735c1.35356-1.18233,2.72121-2.39375,4.28447-3.41312
                c2.01468-1.3315,4.05165-2.10659,6.05664-3.01962c6.51312-2.93597,12.9928-7.17924,19.65207-11.4731
                c3.33649-2.11721,6.70169-4.3078,10.25208-6.12324c3.52927-1.83642,7.12769-3.42679,10.6467-4.91322
                c1.95441-0.84446,4.36081-1.79413,5.43588-2.89005c0.46918-0.45281,0.66809-0.83587,1.36951-1.37417
                c0.60864-0.46727,1.35919-0.85808,2.04657-1.15322c1.07034-0.46884,2.22766-0.84106,3.4191-0.97272l-0.4537,0.71484
                c-0.05914-0.46825-0.20694-0.92107-0.52512-1.27315c-0.31113-0.35398-0.78271-0.61271-1.31964-0.72505
                c-0.42908-0.08652-0.41562-0.02678-0.59277-0.17874c-0.1825-0.14461-0.11185-0.38266,0.37985-0.46352
                c0.28497-0.04044,0.64764-0.00168,0.97836,0.10482c0.52173,0.16013,1.01743,0.47419,1.35739,0.90286
                c0.34839,0.42344,0.53134,0.93093,0.61981,1.41587l0.06631,0.36333l-0.32455,0.04354
                c-1.12427,0.15084-2.21298,0.50842-3.22247,0.92849l0.21518,0.55838c-1.30658,0.70054-2.19598,1.24795-2.93417,1.93725
                c-0.35788,0.3356,0.42847,0.12438-0.50809,0.79551c-0.54169,0.39068-1.29483,0.75358-1.90576,0.98579
                c-0.97504,0.37937-1.87915,0.66926-2.62955,0.83949c-0.53436,0.12094-0.99963,0.18498-1.62637,0.37579l-0.09573,0.02987
                c-0.76453,0.24313-1.6741,0.63985-2.54599,1.04425c-2.72522,1.25572-4.86798,2.58982-7.35788,4.04962
                c-2.44202,1.41596-5.11737,2.94031-7.55939,4.45259c-3.48047,2.14091-6.94724,4.30227-10.39066,6.49981
                c-2.92203,1.86842-6.85626,3.88583-6.51694,5.50679c-1.24948,0.89823-2.77533,1.51954-3.57349,1.46228
                c-0.20978,0.45543-0.42935,0.92573-0.89453,1.46077c-0.46527,0.5351-1.24237,1.15714-1.95956,1.46296
                c-0.69789,0.29819-1.13158,0.22229-1.6248,0.25056c-0.49199,0.02982-1.30061,0.28627-1.90576,0.82018
                c-0.31888,0.28133-0.4735,0.54901-0.6656,0.81523c-0.7903,1.08368-2.51678,2.41928-4.0042,3.09855
                c-0.64009,0.28931-1.308,0.5077-1.92883,0.98342c-0.51227,0.38939-0.79156,0.81712-1.16652,1.23114
                c-1.01903,1.13257-2.70863,2.14501-4.16576,2.92574c-6.78363,3.61295-13.81688,10.27094-20.52104,14.9864
                c-1.23892,0.86503-2.50745,1.75073-3.74532,2.61502c-1.3714,0.97469-2.72202,2.01168-4.0519,3.07861
                c-4.84848,3.86732-9.44835,8.05617-14.00377,12.2387l-2.50827,2.30582c-5.31566,4.89719-10.68169,9.99386-16.22681,14.69542
                c-5.77882,4.89927-11.54008,9.59903-17.05637,14.82781c-3.8383,3.6404-7.58261,7.48315-11.72134,10.69095
                c-1.01474,0.7863-2.04732,1.53134-3.03122,2.38852c-1.2169,1.05798-2.27405,2.2066-3.3663,3.32564
                c-3.30733,3.39856-7.18987,6.62511-10.8896,9.42172c-1.14877,0.86223-2.4942,1.89334-3.1821,2.91325
                c-0.31129,0.45924-0.42088,0.8284-0.74551,1.2955c-0.72472,1.04279-2.06003,2.02417-3.15247,2.89496
                c-1.80658,1.44374-3.58652,3.17142-5.05328,5.09837c-0.35148,0.48163-0.69264,0.97853-0.94095,1.46375
                c-0.11705,0.23976-0.20702,0.4696-0.25361,0.65077c-0.00969,0.04614,0.01199,0.15532-0.04548,0.11662
                c-0.05581,0.00175-0.11377,0.01172-0.17192,0.03073c-0.05334,0.01485-0.13993,0.06378-0.11332,0.04538
                c0,0-0.00387-0.00534-0.01042-0.00394l-0.01151,0.00478l-0.00678,0.00456l-0.00356,0.00287l-0.00182,0.00157
                c-0.07291,0.07616,0.09803-0.1026,0.08132-0.08501l0.00175-0.0016c0.03716-0.03438,0.05525-0.07045,0.06002-0.09785
                c-0.01334,0.01091,0.04369-0.08755,0.05635-0.1252l0.00814-0.00174c-0.01074,0.0181,0.10909-0.00714,0.16391,0.00446
                c0.19177-0.02771,0.42586-0.08304,0.67623-0.17015c0.50243-0.17065,1.03584-0.45042,1.55333-0.7291
                c1.77197-0.99611,3.49982-2.23499,5.19077-3.50154c1.69312-1.27248,3.35562-2.60498,4.99348-3.96895
                c1.63809-1.36409,3.25067-2.76402,4.8259-4.20042c1.57887-1.42885,3.07544-2.93414,4.6274-4.44577
                c6.18494-5.99364,12.64265-11.6284,18.58823-17.67873c1.17285-1.18349,2.34239-2.42371,3.59488-3.49165
                c0.70052-0.61212,1.39613-1.14471,2.08513-1.67281C164.5441,118.59276,166.14774,117.32465,167.7197,116.01358z"/>
            </g>
        </svg>
    )
    const data = useStaticQuery(graphql`
        query IntroImgQuery {
            file(relativePath: {eq: "20190506.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 900, srcSetBreakpoints: [ 430, 960 ], fit: COVER, cropFocus: ATTENTION) {
                        aspectRatio
                        base64
                        sizes
                        src
                        srcSet
                        srcSetWebp
                        srcWebp
                    }
                }
            }
        }
    `)
    return (
        <section className={style.intro}>
            <div className={style.intro__grid}>
                <div className={style.intro__bg} />
                <div className={style.intro__text}>
                    <div>
                        {/*<h5 className="u-mgb--0">Synth polaroid bitters</h5>
                        <h4 className="c-title--normal">Brooklyn, fanny pack</h4>*/}
                        <p className="c-copy">
                            Here’s to the things that truly matter. Good times with good friends and family.
                            Everything else is pretty much overrated. Love you all
                        </p>
                        <p className="c-copy">
                            Thanks for coming and making this an amazing weekend filled with truly
                            unforgettable memories.
                        </p>
                    </div>
                    <div className={style.intro__svg}>
                        <Sign/>
                    </div>
                </div>
                <div className={style.intro__img}>
                    <Img
                        fluid={data.file.childImageSharp.fluid}
                        aspectRatio={87/65}
                        placeholderClassName="img-placeholder"
                    />
                </div>
            </div>
        </section>
    )
}
