import React from "react"
import { Link } from "gatsby"
import style from "./indexLinks.module.scss"

export default () => {
    return (
        <section className={style.links}>
            <ul className={style.links__wrapper}>
                <li className={style.links__item}>
                    <Link
                        to="/photos"
                        className={style.links__link}
                    >
                        <span className={style.links__label}>view all photos</span>
                    </Link>
                </li>
                <li className={style.links__item}>
                    <Link
                        to="/videos"
                        className={style.links__link}
                    >
                        <span className={style.links__label}>watch the videos</span>
                    </Link>
                </li>
            </ul>
        </section>
    )
}
